// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { paths } from '../../config';

const Wrapper = styled.div`
  font-size: 13px;
  color: #7c7c7c;
`;

type Props = {
  className?: string,
}

const defaultProps = {
  className: undefined,
};

// CTA = Call to Action
const MissingInfoCTA = ({ className }: Props) => (
  <Wrapper className={className}>
    <>Is certain information on this page missing or incorrect? </>
    <Link to={paths.contact}>Let us know</Link>
    .
  </Wrapper>
);

MissingInfoCTA.defaultProps = defaultProps;

export default MissingInfoCTA;
