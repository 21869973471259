/**
 * Renders a filterable list of similar alternative items
 * on the single alternative item screen
 *
 * @flow
 */
import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import AnimateHeight from 'react-animate-height';

import Tags from './Tags';
import PostListItem from './PostListItem';
import { UnderlinedToggleButton } from './ToggleButton';

const Wrapper = styled.div`
  margin-top: 100px;

  @media (min-width: 768px) {
    margin-top: 115px;
  }

  @media (min-width: 1200px) {
    margin-top: 230px;
  }
`;

const Filters = styled.div`
  margin-bottom: 23px;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-bottom: 39px;
  }

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

const FiltersHeader = styled.div`
  color: #8e8e8e;
  font-size: 17px;

  > span {
    vertical-align: middle;
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const StyledTags = styled(Tags)`
  padding-top: 20px;

  @media (min-width: 768px) {
    padding-top: 40px;
  }
`;

type Taxonomy = {
  path: string,
  name: string,
};

type Props = {
  defaultCat: ?Taxonomy,
  defaultTag: ?Taxonomy,
};

type Data = {
  allAlternatives: {
    edges: Array<{
      node: {
        title: string,
        excerpt: string,
        path: string,
        type: string,
        featuredImage?: {
          alt: string,
          localFile?: {
            childImageSharp: {
              fluid: any,
            },
          },
        },
        customMeta: {
          commentCount: number,
        },
        categories?: Taxonomy[],
        tags?: Taxonomy[],
      },
    }>,
  },
  allCategories: {
    edges: Array<{
      node: Taxonomy,
    }>,
  },
  allTags: {
    edges: Array<{
      node: Taxonomy,
    }>,
  },
};

type HasTaxFn = (taxonomies: Taxonomy[], tax: Taxonomy) => boolean;
const hasTax: HasTaxFn = (taxonomies, tax) => !!taxonomies.find((t) => t.path === tax.path);

const SimilarAlternatives = (props: Props) => {
  const { defaultCat, defaultTag } = props;

  const data: Data = useStaticQuery(graphql`
    query {
      allAlternatives: allWordpressWpAlternatives {
        edges {
          node {
            title
            excerpt
            path
            type
            featuredImage: featured_media {
              ...FeaturedImage
            }
            customMeta: et_custom_meta {
              commentCount: comment_count
            }
            categories: alternative_categories {
              name
              path
            }
            tags: alternative_tags {
              name
              path
            }
          }
        }
      }
      allCategories: allWordpressWpAlternativeCategories {
        edges {
          node {
            name
            path
          }
        }
      }
      allTags: allWordpressWpAlternativeTags {
        edges {
          node {
            name
            path
          }
        }
      }
    }
  `);

  const taxonomies = useMemo(() => ({
    cat: data.allCategories.edges.map(({ node }) => node),
    tag: data.allTags.edges.map(({ node }) => node),
  }), [data]);

  const alternatives = useMemo(
    () => data.allAlternatives.edges.map(({ node }) => node),
    [data],
  );

  const [activeCat, setActiveCat] = useState<Taxonomy>(defaultCat || taxonomies.cat[0]);
  const [activeTag, setActiveTag] = useState<Taxonomy>(defaultTag || taxonomies.tag[0]);
  const [activeTaxonomyType, setActiveTaxonomyType] = useState<'tag' | 'cat' | null>(null);

  const visibleAlternatives = useMemo(
    () => alternatives
      .filter((alt) => hasTax(alt.categories || [], activeCat) && hasTax(alt.tags || [], activeTag))
      .slice(0, 12), // limit the number of similar items visible to 12
    [alternatives, activeCat, activeTag],
  );

  const toggleCat = useCallback(() => {
    setActiveTaxonomyType(activeTaxonomyType !== 'cat' ? 'cat' : null);
  }, [activeTaxonomyType]);

  const toggleTag = useCallback(() => {
    setActiveTaxonomyType(activeTaxonomyType !== 'tag' ? 'tag' : null);
  }, [activeTaxonomyType]);

  const handleCatClick = useCallback((path) => {
    const newActiveCat = taxonomies.cat.find((cat) => cat.path === path);
    if (newActiveCat) {
      setActiveCat(newActiveCat);
    }
    setActiveTaxonomyType(null);
  }, [data]);

  const handleTagClick = useCallback((path) => {
    const newActiveTag = taxonomies.tag.find((tag) => tag.path === path);
    if (newActiveTag) {
      setActiveTag(newActiveTag);
    }
    setActiveTaxonomyType(null);
  }, [data]);

  return (
    <Wrapper>
      <Filters>
        <FiltersHeader>
          <span>Other </span>
          <UnderlinedToggleButton
            active={activeTaxonomyType === 'cat'}
            onClick={toggleCat}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: activeCat.name }} />
          </UnderlinedToggleButton>
          <span> brands which are </span>
          <UnderlinedToggleButton
            active={activeTaxonomyType === 'tag'}
            onClick={toggleTag}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: activeTag.name }} />
          </UnderlinedToggleButton>
        </FiltersHeader>
        <AnimateHeight
          duration={500}
          animateOpacity
          height={activeTaxonomyType === 'cat' ? 'auto' : 0}
        >
          <StyledTags
            tags={taxonomies.cat}
            activeTag={activeCat}
            onTagClick={handleCatClick}
          />
        </AnimateHeight>
        <AnimateHeight
          duration={500}
          animateOpacity
          height={activeTaxonomyType === 'tag' ? 'auto' : 0}
        >
          <StyledTags
            tags={taxonomies.tag}
            activeTag={activeTag}
            onTagClick={handleTagClick}
          />
        </AnimateHeight>
      </Filters>

      {visibleAlternatives.length > 0 && (
        <div className="row">
          {visibleAlternatives.map((alternative) => (
            <div key={alternative.path} className="col-sm-6 col-md-4 col-lg-3">
              <PostListItem item={alternative} />
            </div>
          ))}
        </div>
      )}
      {visibleAlternatives.length === 0 && (
        <p>
          No alternative brands found matching your filters.
          Please try a different combination.
        </p>
      )}
    </Wrapper>
  );
};

export default SimilarAlternatives;
