// @flow
import * as React from 'react';
import Img from 'gatsby-image';
import styled, { keyframes } from 'styled-components';
import { TextButton } from '../styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div``;

const ActiveImg = styled(Img)`
  height: 54vw;
  min-height: 164px;
  background: #f5f5f5;
  border-radius: 4px;
  animation: ${fadeIn} 0.3s ease-in;
  overflow: hidden;

  @media (min-width: 992px) {
    width: 536px;
    height: 313px;
  }
`;

const Thumbs = styled.div`
  display: flex;
`;

const Thumb = styled(TextButton)`
  display: block;
  margin-top: 7px;
  border-radius: 4px;
  border: 2px solid;
  border-color: ${(props) => (props.active ? '#4a90e2' : 'transparent')} !important;
  overflow: hidden;

  & + & {
    margin-left: 7px;
  }
`;

const ThumbImg = styled(Img)`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 68px;
    height: 68px;
  }
`;

type Props = {
  images: Array<{
    localFile?: {
      childImageSharp: {
        fluid: any,
      },
    },
  }>,
  className?: string,
};

const defaultProps = {
  className: '',
};

const AlternativeGallery = ({
  images,
  className,
}: Props) => {
  const [activeImageIndex, setActiveImageIndex] = React.useState(0);

  const activeImage = images[activeImageIndex]
    ? images[activeImageIndex]
    : images[0];

  return (
    <Wrapper className={className}>
      {activeImage.localFile && (
        <ActiveImg
          fluid={activeImage.localFile.childImageSharp.fluid}
          alt=""
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      )}
      {/* render thumbs only if there are more than one images */}
      {images.length > 1 && (
        <Thumbs>
          {images.map((image, index) => (image.localFile ? (
            <Thumb
              active={activeImageIndex === index}
              key={image.localFile.childImageSharp.fluid.src}
              onClick={() => setActiveImageIndex(index)}
            >
              <ThumbImg
                fluid={image.localFile.childImageSharp.fluid}
                alt=""
              />
            </Thumb>
          ) : null))}
        </Thumbs>
      )}
    </Wrapper>
  );
};

AlternativeGallery.defaultProps = defaultProps;

export default AlternativeGallery;
