/**
 * Renders single alternative item page
 *
 * @flow
 */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import type { Meta } from '../types';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import BlockQuote from '../components/BlockQuote';
import Collapse from '../components/Collapse';
import Tags from '../components/Tags';
import AlternativeGallery from '../components/AlternativeGallery';
import RelatedLinks from '../components/RelatedLinks';
import MissingInfoCTA from '../components/MissingInfoCTA';
import useMediaBreakpoints from '../hooks/useMediaBreakpoints';
import SimilarAlternatives from '../components/SimilarAlternatives';

const certificateImgHeight = 34;

const StyledImg = styled(Img)``;

const EntryHeader = styled.div`
  display: flex;
  margin-bottom: 16px;

  h1 {
    margin-bottom: 3px;
    line-height: 1;
  }

  @media (min-width: 768px) {
    h1 {
      margin-bottom: 6px;
    }
  }
`;

const LogoImg = styled(Img)`
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  margin-right: 14px;

  @media (min-width: 768px) {
    width: 67px;
    height: 67px;
    flex-basis: 67px;
  }

  @media (min-width: 992px) {
    width: 148px;
    height: 148px;
    flex-basis: 148px;
  }

  @media (min-width: 1200px) {
    width: 176px;
    height: 176px;
    flex-basis: 176px;
  }
`;

const Website = styled.span`
  color: #7c7c7c;
`;

const WebsiteIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: #fff;
  background: #b4b4b4;
  font-family: var(--font-family-article-body);
  font-size: 11px;
  line-height: 16px;
  text-align: center;

  &::before {
    content: 'W';
    vertical-align: middle;
  }
`;

const MissionStatementCollapse = styled(Collapse)`
  button {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
`;

const StyledBlockQuote = styled(BlockQuote)`
  margin-top: 26px;
  font-size: 14px;

  @media (min-width: 768px) {
    margin-top: 36px;
  }

  @media (min-width: 992px) {
    margin-top: 42px;
  }
`;

const Products = styled.div`
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const Gallery = styled(AlternativeGallery)`
  margin: 40px 0 26px;

  @media (min-width: 768px) {
    margin-bottom: 72px;
  }
`;

const ReviewsAndCertificates = styled.div`
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const Reviews = styled.div`
  margin: 16px 0 7px;
`;

const Review = styled.div`
  & + & {
    margin-top: 9px;

    @media (min-width: 768px) {
      margin-top: 13px;
    }
  }
`;

const Rating = styled.span`
  display: inline-block;
  width: 25px;
  margin-right: 7px;
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  color: #fff;
  background: ${(props) => (props.rating >= 8 ? '#4ae258' : '#ffb80d')};
  text-align: center;
  vertical-align: center;
  border-radius: 3px;

  @media (min-width: 768px) {
    width: 31px;
    margin-right: 9px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const Reviewer = styled.span`
  font-size: 13px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Certificates = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${StyledImg} {
    height: ${certificateImgHeight}px;
    margin-top: 11px;
  }

  ${StyledImg} + ${StyledImg} {
    margin-left: 11px;
  }
`;

const StyledMissingInfoCTA = styled(MissingInfoCTA)`
  margin: 26px 0 40px;
`;

const Comments = styled.div`
  margin: 40px 0 100px;
`;

type Props = {
  data: {
    alternative: {
      title: string,
      content: string,
      yoastMeta: Meta[],
      customMeta: {
        title: string,
        canonical: string,
        commentCount: number,
      },
      acf?: {
        logo?: {
          localFile?: {
            childImageSharp: {
              fluid: any,
            },
          },
        },
        website?: string,
        missionStatement?: string,
        images?: Array<{
          localFile?: {
            childImageSharp: {
              fluid: any,
            },
          },
        }>,
        reviews?: Array<{
          rating: number,
          reviewer: string,
        }>,
        certificates?: Array<{
          localFile?: {
            childImageSharp: {
              fluid: any,
            },
          },
        }>,
        relatedArticles?: Array<{
          path: string,
          title: string,
          excerpt: string,
        }>,
        aroundTheWebLinks?: Array<{
          url: string,
          title: string,
          description: string,
        }>,
      },
      categories?: Array<{
        name: string,
        path: string,
      }>,
      tags?: Array<{
        name: string,
        path: string,
      }>,
      yoastSchemaJson: string,
    },
  },
};

const PageTemplate = (props: Props) => {
  const { data: { alternative } } = props;

  const {
    isDesktop,
    isTablet,
    isMobile,
  } = useMediaBreakpoints();

  const logoMarkup = alternative.acf && alternative.acf.logo && alternative.acf.logo.localFile ? (
    <LogoImg
      fluid={alternative.acf.logo.localFile.childImageSharp.fluid}
      alt={`${alternative.title} logo`}
    />
  ) : null;

  const productsMarkup = alternative.categories ? (
    <Products>
      <h3>Products</h3>
      <Tags tags={alternative.categories} />
    </Products>
  ) : null;

  const reviewsMarkup = alternative.acf && alternative.acf.reviews ? (
    <Reviews>
      {alternative.acf.reviews.map((review) => (
        <Review key={review.reviewer}>
          <Rating rating={review.rating}>{review.rating}</Rating>
          <Reviewer>{review.reviewer}</Reviewer>
        </Review>
      ))}
    </Reviews>
  ) : null;

  const certificatesMarkup = alternative.acf && alternative.acf.certificates ? (
    <Certificates>
      {alternative.acf.certificates
        .filter((c) => c.localFile).map((certificate) => certificate.localFile && (
          <StyledImg
            key={certificate.localFile.childImageSharp.fluid.src}
            fluid={certificate.localFile.childImageSharp.fluid}
            style={{ width: certificate.localFile.childImageSharp.fluid.aspectRatio * 34 }}
            alt=""
          />
        ))}
    </Certificates>
  ) : null;

  const reviewsAndCertificates = (reviewsMarkup || certificatesMarkup) ? (
    <ReviewsAndCertificates>
      <h3>Reviews & Certificates</h3>
      {reviewsMarkup}
      {certificatesMarkup}
    </ReviewsAndCertificates>
  ) : null;

  const missingInfoCTAMarkup = <StyledMissingInfoCTA />;

  const commentsMarkup = (
    <Comments>
      <h3>{`Responses on ${alternative.title}`}</h3>
    </Comments>
  );

  const relatedArticlesMarkup = alternative.acf && alternative.acf.relatedArticles ? (
    <RelatedLinks
      heading="From our Articles"
      items={
        alternative.acf.relatedArticles.map(({
          excerpt,
          path,
          title,
        }) => ({
          title,
          url: path,
          description: excerpt,
        }))
      }
    />
  ) : null;

  const aroundTheWebLinksMarkup = alternative.acf && alternative.acf.aroundTheWebLinks ? (
    <RelatedLinks
      heading="Around the Web"
      items={alternative.acf.aroundTheWebLinks}
      showUrl
    />
  ) : null;

  return (
    <Layout>
      <SEO
        title={alternative.customMeta.title}
        canonical={alternative.customMeta.canonical}
        meta={alternative.yoastMeta}
      />
      <Helmet>
        <script type="application/ld+json">{alternative.yoastSchemaJson}</script>
      </Helmet>
      <article className="hentry">
        <div className="row">
          <div className="col-lg-2">
            {isDesktop && logoMarkup}
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-7 col-lg-12">
                <EntryHeader>
                  {!isDesktop && logoMarkup}
                  <div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: alternative.title }} />
                    <Website>
                      <WebsiteIcon role="presentation" />
                      {alternative.acf && alternative.acf.website}
                    </Website>
                  </div>
                </EntryHeader>

                {/* eslint-disable-next-line react/no-danger */}
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: alternative.content }} />

                <MissionStatementCollapse title="Mission Statement">
                  <StyledBlockQuote
                    text={alternative.acf && alternative.acf.missionStatement}
                    noQuotes
                  />
                </MissionStatementCollapse>

                {!isMobile && productsMarkup}
              </div>

              <div className="col-md-4 offset-md-1">
                {!isDesktop && reviewsAndCertificates}
                {isTablet && missingInfoCTAMarkup}
              </div>
            </div>

            {isMobile && productsMarkup}

            {alternative.acf && alternative.acf.images && (
              <Gallery images={alternative.acf.images} />
            )}

            {isMobile && missingInfoCTAMarkup}

            {commentsMarkup}
          </div>

          <div className="col-lg-3 offset-lg-1">
            {isDesktop && reviewsAndCertificates}
            {relatedArticlesMarkup}
            {aroundTheWebLinksMarkup}
            {isDesktop && missingInfoCTAMarkup}
          </div>
        </div>
      </article>
      <SimilarAlternatives
        defaultCat={alternative.categories && alternative.categories[0]}
        defaultTag={alternative.tags && alternative.tags[0]}
      />
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    alternative: wordpressWpAlternatives(id: {eq: $id}) {
      title
      content
      yoastMeta: yoast_meta {
        content
        name
        property
      }
      customMeta: et_custom_meta {
        canonical
        commentCount: comment_count
        title
      }
      acf {
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 176) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        website
        missionStatement: mission_statement
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 718) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        reviews {
          rating
          reviewer
        }
        certificates {
          localFile {
            childImageSharp {
              fluid(maxHeight: 34) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        relatedArticles: related_articles {
          path
          title
          excerpt
        }
        aroundTheWebLinks: around_the_web_links {
          url
          title
          description
        }
      }
      categories: alternative_categories {
        name
        path
      }
      tags: alternative_tags {
        name
        path
      }
      yoastSchemaJson: yoast_schema_json
    }
  }
`;
