// @flow
import * as React from 'react';
import styled from 'styled-components';

import { Quote } from '../styles';
import QuotesIcon from '../images/quotes.svg';

const Wrapper = styled.div`
  svg {
    color: #e0edff;
  }
`;

const StyledQuote = styled(Quote)`
  margin: -36px 0 0 22px;
  font-size: 1.17em !important;

  @media (min-width: 768px) {
    margin-left: 32px;
  }

  @media (min-width: 992px) {
    margin-left: 36px;
  }
`;

type Props = {
  text?: string,
  children?: React.Node,
  className?: string,
  noQuotes?: boolean,
};

const defaultProps = {
  text: '',
  children: '',
  className: '',
  noQuotes: false,
};

const BlockQuote = ({
  text,
  children,
  className,
  noQuotes,
}: Props) => {
  const quotationMarkup = text ? (
    <StyledQuote
      noQuotes={noQuotes}
      as="blockquote"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <StyledQuote
      noQuotes={noQuotes}
      as="blockquote"
    >
      {children}
    </StyledQuote>
  );

  return (
    <Wrapper className={className}>
      <QuotesIcon />
      {quotationMarkup}
    </Wrapper>
  );
};

BlockQuote.defaultProps = defaultProps;

export default BlockQuote;
