// @flow
import * as React from 'react';
import styled from 'styled-components';

import Link from './Link';

const Wrapper = styled.div`
  margin: 60px 0;

  ol {
    list-style: none;
    padding-left: 31px;
    counter-reset: ordered-list-item-number;

    li {
      margin-bottom: 5px;
      counter-increment: ordered-list-item-number;

      &::before {
        position: absolute;
        content: counter(ordered-list-item-number);
        display: inline-block;
        width: 31px;
        margin-left: -31px;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.2;
        font-family: var(--font-family-heading);
        color: #c3c3c3;
        vertical-align: middle;
      }
    }

    @media (min-width: 768px) {
      padding-left: 36px;

      li::before {
        width: 36px;
        margin-left: -36px;
      }
    }
  }

  p {
    margin-top: 11px;
  }

  a {
    font-weight: 500;
  }
`;

const Row = styled.div``;

const RelatedLink = styled.div`
  margin: 10px 0;
`;

const URLText = styled.div`
  margin: 6px 0 8px;
  font-size: 13px;
  color: #7c7c7c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type RelatedLinksItem = {
  title: string,
  url: string,
  description: string,
};

type Props = {
  items: Array<RelatedLinksItem>,
  heading: string,
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6,
  showUrl?: boolean,
  isNumberedList?: boolean,
};

const defaultProps = {
  headingLevel: 3,
  showUrl: false,
  isNumberedList: false,
};

const RelatedLinks = (props: Props) => {
  const {
    items,
    heading,
    headingLevel = 3,
    showUrl,
    isNumberedList,
  } = props;

  const HeadingComponent = `h${headingLevel}`;

  return (
    <Wrapper>
      <HeadingComponent>{heading}</HeadingComponent>
      <Row as={isNumberedList ? 'ol' : 'div'} className="row justify-content-between">
        {items.map((item) => (
          <RelatedLink key={item.url} as={isNumberedList ? 'li' : 'div'} className="col-md-5 col-lg-12">
            <Link to={item.url} rel="bookmark">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: item.title }} />
            </Link>
            {showUrl && (<URLText>{item.url.replace(/^https?:\/\//, '')}</URLText>)}
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
          </RelatedLink>
        ))}
      </Row>
    </Wrapper>
  );
};

RelatedLinks.defaultProps = defaultProps;

export default RelatedLinks;
